import React from 'react'
import { Link } from 'gatsby'

import Hamburger from '../assets/icons/orca-hamburger.svg'
import OLogo from '../assets/logos/OrcaSpaces_Logo_white-compressor.png'
import '../styles/components/_header.scss'

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = { isOpen: false }
    this.changeMenu = this.changeMenu.bind(this)
  }

  componentDidMount () {
    const navbar = document.getElementById('navbar')
    navbar.style.backgroundColor = 'transparent'

    if (typeof window !== 'undefined') {
      window.onscroll = function () {
        const currentScrollPos = window.pageYOffset
        if (currentScrollPos > 400) {
          navbar.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'
        } else {
          navbar.style.backgroundColor = 'transparent'
        }
      }
    }
  }

  changeMenu () {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render () {
    return (
      <nav id="navbar" className="navbar is-fixed-top">
        <div className="navbar-brand">
          <Link to={'/'} className="navbar-item">
            <img src={OLogo} alt="OrcaSpaces" />
          </Link>

          <button
            className="navbar-burger"
            aria-label="Open Navigation Menu"
            onClick={this.changeMenu}
            onKeyUp={this.changeMenu}
            style={{ cursor: 'pointer' }}
          >
            <Hamburger
              width="30"
              onClick={this.changeMenu}
              onKeyUp={this.changeMenu}
              aria-label="Open Menu"
            />
          </button>
          <div className={`modal ${this.state.isOpen ? 'is-active' : ''}`}>
            <div className="modal-background" />
            <div className="modal-content menu-overlay">
              <Link
                to={'/antimicrobial-door-protection'}
                className="navbar-item has-text-white is-uppercase"
              >
                antimicrobial door protection
              </Link>
              <Link
                to={'/about'}
                className="navbar-item has-text-white is-uppercase"
              >
                about
              </Link>
              <Link
                to={'/projects'}
                className="navbar-item has-text-white is-uppercase"
              >
                projects
              </Link>
              <Link
                to={'/resources'}
                className="navbar-item has-text-white is-uppercase"
              >
                resources
              </Link>
              <Link
                to={'/site-survey'}
                className="navbar-item has-text-white is-uppercase"
              >
                site survey
              </Link>
              <Link
                to={'/contact'}
                className="navbar-item has-text-white is-uppercase"
              >
                contact
              </Link>
            </div>
            <button
              className="modal-close is-large"
              onClick={this.changeMenu}
              aria-label="Close Navigation Menu"
            />
          </div>
        </div>
        <div className="navbar-menu">
          <div className="navbar-end">
            <a href="#signup-form" className="navbar-item has-text-white">
              <span className="is-signup-link has-text-weight-light">
                Sign Up
              </span>
            </a>
            <div
              className={`navbar-item ${this.state.isOpen ? 'is-active' : ''}`}
            >
              <Hamburger
                className="hamburger-icon"
                width="30"
                onClick={this.changeMenu}
                onKeyUp={this.changeMenu}
                aria-label="Open Menu"
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Header
