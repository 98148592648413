import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { searchBrand } from 'fa-icons-search'

const SocialLink = ({ link }) => {
  const { name: icon } = searchBrand(link.linkName)
  return (
    <a
      href={link.url}
      target="_blank"
      rel="noopener noreferrer"
      className="footer-social-icon"
      aria-label={link.linkName}
    >
      <span hidden>{link.linkName}</span>
      <FontAwesomeIcon icon={['fab', icon]} size="1x" />
    </a>
  )
}

SocialLink.propTypes = {
  link: PropTypes.object.isRequired
}

export default SocialLink
