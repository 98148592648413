import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import EmailSignup from './emailSignup'
import SocialLink from './SocialLink'

import OCompass from '../assets/logos/Orca_Compass_white-compressor.png'
import '../styles/components/_footer.scss'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query SiteFooterQuery {
        datoCmsFooter {
          footerLink {
            ... on DatoCmsLink {
              id
              linkText
              linkUrl
              model {
                apiKey
              }
            }
            ... on DatoCmsSocialLink {
              id
              linkName
              url
              model {
                apiKey
              }
            }
          }
          footerDetails
        }
      }
    `}
    render={({ datoCmsFooter: footer }) => (
      <section className="hero is-medium is-black is-footer has-text-centered">
        <div className="columns has-text-centered">
          <div className="column is-3 is-footer-column">
            <h3 className="has-text-weight-bold is-uppercase">about us</h3>
            {footer.footerLink
              .filter(link => link.model.apiKey === 'link')
              .map(link => {
                if (link.linkUrl.includes('http')) {
                  return (
                    <p key={link.id}>
                      <a
                        href={link.linkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.linkText}
                      </a>
                      <br />
                    </p>
                  )
                } else {
                  return (
                    <p key={link.id}>
                      <Link to={link.linkUrl} className="footer-link">
                        {link.linkText}
                      </Link>
                      <br />
                    </p>
                  )
                }
              })}
          </div>
          <div className="column is-footer-middle-column">
            <figure className="image is-128x128">
              <img
                src={OCompass}
                className="footer-logo"
                alt="Orcatec Compass Logo"
              />
            </figure>
            <h1 className="subtitle is-size-4">
              <span className="is-uppercase">ORCA</span>
              <span className="has-text-weight-light is-uppercase footer-site-text">
                SPACES
              </span>
            </h1>
          </div>
          <div className="column is-3 is-footer-column">
            <h3 className="has-text-weight-bold is-uppercase">
              stay connected
            </h3>
            <EmailSignup />
            <div className="footer-social-icons">
              {footer.footerLink
                .filter(link => link.model.apiKey === 'social_link')
                .map(link => (
                  <SocialLink key={link.id} link={link} />
                ))}
            </div>
          </div>
        </div>
        <p className="has-text-white footer-company-text">
          <small>{footer.footerDetails}</small>
        </p>
      </section>
    )}
  />
)

export default Footer
